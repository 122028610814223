<template>
  <div id="app" class="App">
    <img class="App-logo" src="~images/logo-white.svg?file">
    <router-view></router-view>
  </div>
</template>

<script>
  export default {
    name: 'app'
  }
</script>

<style lang="stylus">
  @require '~global/reset'
  @require '~global/variables'
  @require '~global/typography'
  @require '~global/button'

  html
    box-sizing: border-box

  *, *:before, *:after
    box-sizing: inherit

  body
    @extend .Typography-base
    background: $Color-black

  .App
    display: flex
    justify-content: center
    align-items: center
    height: 100vh

  .App-logo
    width: 300px
    top: 60px
    position: absolute
</style>


