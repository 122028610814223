import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);
import Converter from 'components/converter'

export default new Router({
  mode: 'history',

  routes: [
    {
      path: '/',
      name: 'Converter',
      component: Converter
    }
  ]
})

