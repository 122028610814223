<template>
  <div class="Converter">

    <div class="Converter-field"  v-show="false">
      <label class="Converter-label">Retailer</label>

      <select class="Converter-input" v-model="retailer">
        <option v-for="template in templates" :value="template.value" :key="template.value">
          {{ template.label }}
        </option>
      </select>
    </div>

    <div class="Converter-field">
      <label class="Converter-label">PDF</label>
      <input class="Converter-input" type="file" ref="file" @click="reset">
    </div>

    <div class="Converter-field" v-show="false">
      <label class="Converter-label">Sort by color</label>
      <input class="Converter-input" type="checkbox" v-model="sort" :checked="sort">
    </div>

    <a class="Button" @click="upload" v-if="!loading && !downloadUrl">Upload</a>
    <a class="Button" @click="download" :href="downloadUrl" v-if="downloadUrl">Download xls</a>
    <img class="Converter-loading" src="~images/spinner.svg?file" v-if="loading">
  </div>
</template>

<script>
  export default {
    name: 'converter',
    data: () => ({
      retailer: 'ahlens',
      templates: [
        {label: 'Länna', value: 'lanna'},
        {label: 'Room', value: 'room'},
        {label: 'Åhlens', value: 'ahlens'}
      ],

      loading: false,
      formData: null,
      downloadUrl: null,
      sort: false
    }),

    methods: {
      reset() {
        this.loading = false
        this.downloadUrl = null
        this.formData = null
        this.$refs.file.value = ''
      },

      readFile(e) {
        const file = e.target.files[0];
        this.formData = new FormData();
        this.formData.append('pdf', file);
        this.formData.append('retailer', this.retailer);
        this.formData.append('sort', this.sort);
      },

      download() {
        setTimeout(() => {
          this.reset()
        }, 100)

        window.location.href = this.downloadUrl
      },

      async upload() {
        const file = this.$refs.file.files[0]

        if(file == null) {
          alert('No pdf selected');
          return;
        }

        this.loading = true
        //const endpoint = window.location.hostname == 'localhost' ? 'http://localhost:5000' : 'https://vu0bmtcqoj.execute-api.eu-north-1.amazonaws.com/production';
        const endpoint = 'https://vu0bmtcqoj.execute-api.eu-north-1.amazonaws.com/production'
        const url = `${endpoint}/pdf`
        try {
          const response = await fetch(url, {
            method: 'POST',
            body: file
          });

          const data = await response.json()
          this.downloadUrl = data.file
          this.loading = false
        } catch(e) {
          console.log(e);
          alert('Something went wrong =(')
        } finally {
          this.loading = false
        }
      }
    },

    mounted() {
    }
  }
</script>

<style lang="stylus">
  @require '~global/variables'

  .Converter
    background: $Color-white
    color: $Color-black
    width: 360px
    padding: 40px

  .Converter-field
    margin-bottom: 20px

  .Converter-field:last-of-type
    margin-bottom: 14*3px

  .Converter-label
    display: inline-block
    width: 100px

  .Converter-input
    width: 154px
    display: inline-block
    position: relative

  .Converter-loading
    width: 66px
    height: 30px
    transform: translateX(-50%)
    position: relative
    left: 50%
</style>
